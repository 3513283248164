// src/layouts/PublicLayout.tsx
import React from 'react';
import MainLayout from './MainLayout';
import { Outlet } from 'react-router-dom';

export const PublicLayout: React.FC = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};