// src/layouts/ProtectedLayout.tsx
import React from 'react';
import { CampaignProvider, useCampaign } from '../contexts/CampaignContext';
import MainLayout from './MainLayout';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/AuthProvider';

const ProtectedLayoutContent = () => {
  const { session, signOut } = useAuth();
  const location = useLocation();
  const { setShowOnboarding } = useCampaign();

  if (!session) {
    signOut();
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <MainLayout setShowOnboarding={setShowOnboarding}>
      <Outlet />
    </MainLayout>
  );
};

export const ProtectedLayout: React.FC = () => {
  return (
    <CampaignProvider>
      <ProtectedLayoutContent />
    </CampaignProvider>
  );
};