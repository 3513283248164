import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';
import { StepStatus } from '@/utils/Interfaces';

interface OnboardingPaymentInfo {
  setup_intent_id: string;
  payment_id: string;
  budget: number;
}

interface CampaignSummaryProps {
  onStepComplete?: (status: StepStatus) => void;
  onClose?: () => void;
}

const CampaignSummaryOverlay: React.FC<CampaignSummaryProps> = ({ onStepComplete, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [conversionData, setConversionData] = useState<any>(null);
  const [locationData, setLocationData] = useState<any>(null);
  const [dailyBudget, setDailyBudget] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      // Load all data from localStorage
      const conversion = localStorage.getItem('conversion_profile');
      const location = localStorage.getItem('onboarding_overlay_location_data');
      const dailyBudget = localStorage.getItem('daily_budget');

      if (conversion) setConversionData(JSON.parse(conversion));
      if (location) setLocationData(JSON.parse(location));
      if (dailyBudget) setDailyBudget(parseInt(dailyBudget));

      // Validate that we have all required data
      const isComplete = conversion && location && dailyBudget;
      
      if (onStepComplete) {
        console.log(`Truthy conversion: [${conversion}] location: [${location}] dailyBudget: [${dailyBudget}]`);
        onStepComplete({
          isComplete: false,
          message: !isComplete ? 'Missing required campaign information' : undefined
        });
      }

      // Simulate a small delay to prevent flash
      await new Promise(resolve => setTimeout(resolve, 500));
      setIsLoading(false);
    };

    loadData();
  }, [onStepComplete]);

  const handleCreateCampaign = async () => {
      setIsSubmitting(true);

      // Clean up localStorage
      localStorage.removeItem('payment_setup_data');
      localStorage.removeItem('onboarding_overlay_location_data');
      localStorage.removeItem('conversion_profile');
      localStorage.removeItem('campaignId');
      
      toast.success('Campaign created successfully!');
      
      // Close the overlay and navigate
      if (onClose) {
        onClose();
      }
      navigate('/manage-campaign');
  };

  if (isLoading) {
    return (
      <Card className="w-full max-w-2xl mx-auto">
        <CardContent className="p-6">
          <div className="flex flex-col items-center justify-center space-y-4">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
            <p className="text-gray-600">Loading campaign details...</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!conversionData || !locationData) {
    return (
      <Card className="w-full max-w-2xl mx-auto">
        <CardContent className="p-6">
          <div className="text-center text-red-600">
            Missing required campaign information. Please complete all previous steps.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <h2 className="text-2xl font-bold">Campaign Summary</h2>
        <p className="text-gray-600">Review your campaign details before creation</p>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Budget Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Budget</h3>
          <p className="text-gray-600">Daily budget: ${dailyBudget}</p>
        </div>

        {/* Location Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Location Targeting</h3>
          <p className="text-gray-600">
            {locationData.resource_name || 'Location information not available'}
          </p>
        </div>

        {/* Conversion Profile */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Conversion Profile</h3>
          <div className="space-y-2">
            <p className="text-gray-600">Type: {conversionData.conversionType}</p>
            <p className="text-gray-600">
              {conversionData.conversionType === 'leads' ? 'Monthly leads: ' : 'Monthly sales: '}
              {conversionData.monthly}
            </p>
            <p className="text-gray-600">
              {conversionData.conversionType === 'leads' ? 'Average customer spend: ' : 'Average order value: '}
              ${conversionData.average}
            </p>
            <p className="text-gray-600">Estimated profit: ${conversionData.profit}</p>
            {conversionData.conversionType === 'leads' && (
              <p className="text-gray-600">Conversion rate: {conversionData.conversionRate[0]}%</p>
            )}
          </div>
        </div>

        <Button 
          onClick={handleCreateCampaign}
          className="w-full bg-blue-600 hover:bg-blue-700"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Creating Campaign...
            </span>
          ) : (
            'Confirm & Create Campaign'
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CampaignSummaryOverlay;