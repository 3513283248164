import React, { useState, useEffect } from 'react';
import { AlertCircle, Save, Loader2 } from 'lucide-react';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '@/utils/AuthProvider';
import { useCampaign } from '@/contexts/CampaignContext';
import { AdItem, CreativeType, FetchAdCreativeResponse } from './page_types/ad_content';


const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, x: -20 }
};

interface EditorProps {
  items: AdItem[];
  onUpdate: (items: AdItem[]) => void;
  isPending: boolean;
  type: CreativeType;
  maxItems: number;
}

const CreativeEditor: React.FC<EditorProps> = ({ 
  items, 
  onUpdate, 
  isPending, 
  type, 
  maxItems 
}) => {
  const [newItem, setNewItem] = useState('');
  const isHeadline = type === 'HEADLINE';

  const addItem = () => {
    if (newItem && items.length < maxItems) {
      onUpdate([...items, { 
        text: newItem, 
        status: 'PENDING_ADD',
        type 
      }]);
      setNewItem('');
      toast.success(`${isHeadline ? 'Headline' : 'Description'} added - remember to save your changes!`);
    } else if (items.length >= maxItems) {
      toast.warning(`Maximum number of ${isHeadline ? 'headlines' : 'descriptions'} reached (${maxItems})`);
    }
  };

  const removeItem = (index: number) => {
    const updatedItems = [...items];
    if (updatedItems[index].status === 'PENDING_ADD') {
      updatedItems.splice(index, 1);
      toast.info(`Pending ${isHeadline ? 'headline' : 'description'} removed`);
    } else {
      updatedItems[index].status = 'PENDING_REMOVE';
      toast.info(`${isHeadline ? 'Headline' : 'Description'} marked for removal - remember to save your changes!`);
    }
    onUpdate(updatedItems);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white p-6 rounded-lg shadow ${!isHeadline ? 'mt-6' : ''}`}
    >
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl font-semibold">{isHeadline ? 'Headlines' : 'Descriptions'}</h2>
        <AnimatePresence>
          {isPending && (
            <motion.span
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="text-yellow-600 text-sm flex items-center gap-1"
            >
              <AlertCircle size={16} />
              Unsaved changes
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      <p className="text-gray-600 mb-4">
        {isHeadline 
          ? 'The titles of your ads, mixed and matched for better performance. Add up to 15.'
          : 'The text in your ads, paired with various headlines. Add up to 4.'
        }
      </p>
      
      <AnimatePresence>
        {items.map((item, index) => (
          item.status !== 'PENDING_REMOVE' && (
            <motion.div
              key={`${item.type}-${index}-${item.text}`}
              variants={itemVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              layout
              className="flex gap-2 mb-2 items-center"
            >
              {item.status === 'PENDING_ADD' ? (
                <div className="flex-grow p-2 border rounded bg-blue-50 flex justify-between items-center">
                  <span>{item.text}</span>
                  <span className="text-sm text-blue-600">Pending Save</span>
                </div>
              ) : (
                <div className="flex-grow p-2 border rounded">
                  {item.text}
                </div>
              )}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => removeItem(index)}
                className="px-3 py-2 text-red-600 hover:bg-red-50 rounded"
              >
                Remove
              </motion.button>
            </motion.div>
          )
        ))}
      </AnimatePresence>
      
      <div className="flex mt-4">
        {isHeadline ? (
          <input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Enter a new headline"
            className="flex-grow p-2 border rounded-l"
            onKeyPress={(e) => e.key === 'Enter' && addItem()}
          />
        ) : (
          <textarea
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Enter a new description"
            className="flex-grow p-2 border rounded-l"
            rows={2}
          />
        )}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={addItem}
          disabled={items.filter(i => i.status !== 'PENDING_REMOVE').length >= maxItems}
          className="bg-blue-500 text-white px-4 py-2 rounded-r disabled:bg-blue-300"
        >
          Add {isHeadline ? 'headline' : 'description'}
        </motion.button>
      </div>
    </motion.div>
  );
};

const ManageAdContent: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    fetchAdCreativeIfNeeded,
    handleSaveAdCreative,
    setAdCreatives,
    adCreatives,
    } = useCampaign();

  const headlines = adCreatives.filter(item => item.type === 'HEADLINE');
  const descriptions = adCreatives.filter(item => item.type === 'DESCRIPTION');

  const hasPendingChanges = adCreatives.some(
    item => item.status === 'PENDING_ADD' || item.status === 'PENDING_REMOVE'
  );

  const handleUpdate = (newItems: AdItem[]) => {
    const existingItems = adCreatives.filter(item => item.type !== newItems[0].type);
    setAdCreatives([...existingItems, ...newItems]);
  };

  useEffect(() => {
    fetchAdCreativeIfNeeded();
  }, [fetchAdCreativeIfNeeded]); 

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <CreativeEditor 
        items={headlines}
        onUpdate={(items) => handleUpdate(items)}
        isPending={headlines.some(h => h.status !== 'SYNCED')}
        type="HEADLINE"
        maxItems={15}
      />
      
      <CreativeEditor 
        items={descriptions}
        onUpdate={(items) => handleUpdate(items)}
        isPending={descriptions.some(d => d.status !== 'SYNCED')}
        type="DESCRIPTION"
        maxItems={4}
      />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex justify-end mt-6"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleSaveAdCreative}
          disabled={!hasPendingChanges || isSaving}
          className="flex items-center gap-2 bg-green-600 text-white px-6 py-3 rounded-lg disabled:bg-gray-400"
        >
          {isSaving ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <Save size={20} />
          )}
          Save Changes
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default ManageAdContent;