const PrivacyPolicy = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-4"><strong>Last updated:</strong> 10/21/2024</p>
      
      <p className="mb-4">
        Welcome to Multiply ("Company," "we," "our," or "us"). This Privacy Policy explains how we collect, use, 
        disclose, and safeguard your information when you visit our website https://trymultiply.com/ (the "Site"). 
        Please read this privacy policy carefully. If you do not agree with the terms of this policy, please do not 
        access the Site.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">1. Information We Collect</h2>
      <p className="mb-4">
        We collect personal information you provide directly when creating an account, making a purchase, or 
        otherwise interacting with the Site. This includes:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Personal Data: Names, email addresses, usernames, billing addresses, and debit/credit card numbers.</li>
        <li className="mb-2">Authentication Data: Provider tokens and credentials when you connect third-party services.</li>
        <li className="mb-2">Derivative Data: Information our servers automatically collect when you access the Site, such as log and usage data, device data, and location data.</li>
      </ul>
      <p className="mb-4">
        You may also register on the Site using your Google account. By doing so, we collect and use information 
        consistent with Google's terms of service.
      </p>
      <p className="mb-4">We do not knowingly collect data from or target individuals under the age of 18.</p>

      <h2 className="text-xl font-bold mt-6 mb-2">2. How We Use Your Information</h2>
      <p className="mb-4">We use your information to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Deliver and facilitate the delivery of services to you.</li>
        <li className="mb-2">Fulfill contractual obligations, including processing payments via Stripe.</li>
        <li className="mb-2">Respond to your inquiries and send you administrative information.</li>
        <li className="mb-2">Deliver targeted advertising and promotional communications.</li>
        <li className="mb-2">Analyze trends and user behavior to enhance our services.</li>
        <li className="mb-2">Ensure the security of the Site.</li>
        <li className="mb-2">Request feedback and improve the user experience.</li>
        <li className="mb-2">Connect with third-party services on your behalf when authorized.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">3. Data Storage and Security</h2>
      <p className="mb-4">
        We implement appropriate technical and organizational measures to protect your personal information, including:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Encryption of sensitive data at rest and in transit</li>
        <li className="mb-2">Storage of information in secure, password-protected databases</li>
        <li className="mb-2">Limited access to personal information by authorized personnel only</li>
        <li className="mb-2">Regular security assessments and updates</li>
      </ul>
      <p className="mb-4">For authentication tokens:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Tokens are encrypted and stored in secure, password-protected databases</li>
        <li className="mb-2">Tokens are retained for a maximum of 24 hours</li>
        <li className="mb-2">Access to tokens is strictly limited and monitored</li>
        <li className="mb-2">Tokens are automatically deleted after the retention period expires</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">4. Third-Party Service Providers</h2>
      <p className="mb-4">We may share your information with third-party service providers to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Process payments (Stripe)</li>
        <li className="mb-2">Manage advertising campaigns (Google Ads)</li>
        <li className="mb-2">Analyze Site usage</li>
        <li className="mb-2">Provide customer support</li>
        <li className="mb-2">Facilitate email communications</li>
      </ul>
      <p className="mb-4">
        These service providers are contractually obligated to protect your information and may only use it for 
        the specific services they provide to us.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">5. Your Rights</h2>
      <p className="mb-4">You have the right to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Access your personal information</li>
        <li className="mb-2">Correct inaccurate data</li>
        <li className="mb-2">Request deletion of your data</li>
        <li className="mb-2">Withdraw consent for data processing</li>
        <li className="mb-2">Request a copy of your data</li>
        <li className="mb-2">Opt-out of marketing communications</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">6. Data Retention</h2>
      <p className="mb-4">
        We retain your personal information for as long as necessary to provide our services and comply with 
        legal obligations. Specific retention periods include:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Authentication tokens: 24 hours</li>
        <li className="mb-2">Account information: Duration of active account plus 30 days after deletion</li>
        <li className="mb-2">Payment information: As required by financial regulations</li>
        <li className="mb-2">Usage logs: 90 days</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">7. International Data Transfers</h2>
      <p className="mb-4">
        Your information may be transferred to and processed in countries other than your country of residence. 
        We ensure appropriate safeguards are in place for such transfers.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">8. Changes to This Policy</h2>
      <p className="mb-4">
        We may update this privacy policy from time to time. We will notify you of any changes by posting the 
        new policy on this page and updating the "Last updated" date.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">9. California Privacy Rights</h2>
      <p className="mb-4">California residents have additional rights under the CCPA, including the right to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Know what personal information is collected</li>
        <li className="mb-2">Know if personal information is sold or disclosed</li>
        <li className="mb-2">Opt-out of the sale of personal information</li>
        <li className="mb-2">Request deletion of personal information</li>
        <li className="mb-2">Access personal information</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">10. Cookie Policy</h2>
      <p className="mb-4">
        We use cookies and similar tracking technologies to improve your browsing experience. You can control 
        cookie settings through your browser preferences.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">11. Children's Privacy</h2>
      <p className="mb-4">
        Our Site is not intended for children under 18. We do not knowingly collect or maintain information from 
        persons under 18.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">12. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this privacy policy or how your data is handled, please 
        contact us at: support@trymultiply.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;