// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './utils/AuthProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Layouts
import { ProtectedLayout } from './layouts/ProtectedLayout';
import { PublicLayout } from './layouts/PublicLayout';

// Pages
import OnboardingStartPage from './pages/OnboardingStartPage';
import ManageCampaignPage from './pages/ManageCampaignPage';
import OnboardingContainer from './components/OnboardingContainer';
import GoogleAuthFlowContainer from './pages/GoogleAuthFlowContainer';
import AuthCallback from './pages/AuthCallback';
import { DentistOfficeLandingPage, PersonalInjuryLandingPage } from './pages/VerticalLandingPage';
import PrivacyPolicy from './documents/privacy_policy';
import TermsOfService from './documents/toc';
import { DocumentPage } from './components/DocumentPage';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Protected Routes - with CampaignProvider */}
          <Route element={<ProtectedLayout />}>
            <Route path="/manage-campaign" element={<ManageCampaignPage />} />
            <Route path="/account/auth/callback" element={<AuthCallback />} />
          </Route>

          {/* Public Routes - without CampaignProvider */}
          <Route element={<PublicLayout />}>
            <Route path="/" element={<OnboardingStartPage />} />
            <Route path="/onboarding" element={<OnboardingContainer />} />
            <Route path="/onboarding/link-google-account" element={<GoogleAuthFlowContainer />} />
            <Route path="/personal-injury-law" element={<PersonalInjuryLandingPage />} />
            <Route path="/dentistry" element={<DentistOfficeLandingPage />} />
            <Route path="/privacy-policy" element={
              <DocumentPage title="Privacy Policy">
                <PrivacyPolicy />
              </DocumentPage>
            } />
            <Route path="/terms-of-service" element={
              <DocumentPage title="Terms of Service">
                <TermsOfService />
              </DocumentPage>
            } />
          </Route>
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
};

export default App;