import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { DOMAIN_KEY } from '@/types/constants';

interface OnboardingStartProps {
  onComplete: (url: string) => void;
}

const OnboardingOverlayStart: React.FC<OnboardingStartProps> = ({ onComplete }) => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleAnalyze = async () => {
    if (!websiteUrl || isAnalyzing) return;
    setIsAnalyzing(true);
    localStorage.setItem(DOMAIN_KEY, websiteUrl);
    onComplete(websiteUrl);
  };

  const validateUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">
          Launch Your Ad Campaign in 5 minutes
        </h2>
        <p className="text-gray-600 mb-6">
          Enter your website URL and we'll help you create highly targeted ads
        </p>
      </div>
      
      <div className="space-y-4">
        <Input
          type="url"
          placeholder="https://www.your-website.com"
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
          className="w-full p-3 text-lg"
          disabled={isAnalyzing}
        />
        <Button
          onClick={handleAnalyze}
          disabled={!websiteUrl || isAnalyzing || !validateUrl(websiteUrl)}
          className="w-full py-3 text-lg text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-400"
        >
          {isAnalyzing ? (
            <span className="flex items-center justify-center">
              <svg 
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24"
              >
                <circle 
                  className="opacity-25" 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                />
                <path 
                  className="opacity-75" 
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Analyzing Website...
            </span>
          ) : (
            'Start AI Analysis'
          )}
        </Button>
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-3">
          What happens next?
        </h2>
        <ul className="space-y-2">
          {[
            "We'll analyze your website content and structure",
            "Our AI will generate tailored ad copy and keywords",
            "You'll get a preview of potential ad campaigns",
            "Fine-tune and launch your optimized ads with one click"
          ].map((step, index) => (
            <motion.li
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <svg 
                className="w-6 h-6 text-green-500 mr-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7" 
                />
              </svg>
              <span className="text-gray-600">{step}</span>
            </motion.li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default OnboardingOverlayStart;