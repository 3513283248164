// src/layouts/MainLayout.tsx
import React from 'react';
import NavBar from './NavBar';

interface MainLayoutProps {
  children: React.ReactNode;
  setShowOnboarding?: (show: boolean) => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, setShowOnboarding }) => {
  return (
    <div className="min-h-screen bg-background text-neutralDark">
      <NavBar setShowOnboarding={setShowOnboarding} />
      <main style={{ paddingTop: 73 }}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;