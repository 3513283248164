import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/utils/AuthProvider';
import { useCampaign } from '@/contexts/CampaignContext';
import { ACTIVE_CAMPAIGN_ID } from '@/types/constants';
import { Loader2 } from 'lucide-react';

const LoadingScreen = () => (
  <div className="min-h-screen flex flex-col items-center justify-center bg-white">
    <div className="flex flex-col items-center space-y-6 max-w-sm mx-auto p-8 text-center">
      <img src="/aperture-thin.png" alt="Multiply" className="h-12 mb-2" />
      
      <div className="flex items-center justify-center">
        <Loader2 className="animate-spin h-6 w-6 text-blue-600 mr-2" />
      </div>
      
      <div className="space-y-2">
        <h2 className="text-xl font-semibold text-gray-900">
          Setting up your account
        </h2>
        <p className="text-gray-600">
          We're configuring your Multiply dashboard. This will only take a moment.
        </p>
      </div>
    </div>
  </div>
);

const AuthCallback = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { getUserStatus, session, refreshSession } = useAuth();
  const { handleFetchCampaignsIfNeeded, fetchCampaignStatusIfNeeded } = useCampaign();

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        let currentSession = session;
        if (!currentSession?.access_token) {
          currentSession = await refreshSession();
        }
        if (!currentSession?.access_token) {
          navigate('/login');
          return;
        }

        const userStatus = await getUserStatus();
        if (!userStatus) {
          navigate('/');
          return;
        }

        if (!userStatus?.oauth2_linked) {
          navigate('/onboarding/link-google-account?step=signin');
          return;
        }

        // Load campaign data before proceeding
        try {
          await Promise.all([
            fetchCampaignStatusIfNeeded(),
            handleFetchCampaignsIfNeeded()
          ]);
        } catch (error) {
          console.error('Failed to load campaign data:', error);
          navigate('/');
          return;
        }

        if (userStatus?.is_onboarded && userStatus.active_campaign_id) {
          localStorage.setItem(ACTIVE_CAMPAIGN_ID, userStatus.active_campaign_id);
          navigate('/manage-campaign');
        } else if (!userStatus?.account_linked) {
          navigate('/onboarding/link-google-account?step=loading');
        } else if (!userStatus?.is_onboarded) {
          navigate('/onboarding?step=4');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };
    checkUserStatus();
  }, []);

  return isLoading ? <LoadingScreen /> : null;
};

export default AuthCallback;