// src/components/ConditionalCampaignNav.tsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CampaignNav } from './CampaignNav';

export const ConditionalCampaignNav = () => {
  const location = useLocation();
  const isCampaignRoute = location.pathname.includes('/manage-campaign') || 
                         location.pathname.includes('/account/auth/callback');

  if (!isCampaignRoute) return null;
  
  return <CampaignNav />;
};