// src/components/DocumentPage.tsx
import React from 'react';

export const DocumentPage: React.FC<{ 
  children: React.ReactNode; 
  title: string;
}> = ({ children, title }) => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">{title}</h1>
      <div className="max-w-none">
        {children}
      </div>
    </div>
  );
};