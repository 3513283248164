import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OverlayLocationTargetingPage from './OverlayLocationTargetingPage';
import OnboardingOverlayStart from './OverlayStart';
import { StepStatus } from '@/utils/Interfaces';
import { DOMAIN_KEY } from '@/types/constants';
import OverlaySetBudgetPage from './OverlaySetBudget';
import OverlayConversionProfile from './OverlayConversionProfile';
import CampaignSummaryOverlay from './CreateCampaignStep';
import GoogleAdPreviewOverlay from './GoogleAdPreviewOverlay';

interface OnboardingOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  domain?: string;
}

type StepComponent = React.ComponentType<any>;

const OnboardingOverlay: React.FC<OnboardingOverlayProps> = ({ isOpen, onClose, domain }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mounted, setMounted] = useState(false);
  const [animatingOut, setAnimatingOut] = useState(false);
  const [localDomain, setLocalDomain] = useState<string>(domain || '');
  const [isStepTransitioning, setIsStepTransitioning] = useState(false);

  const steps: StepComponent[] = [
    OnboardingOverlayStart,
    GoogleAdPreviewOverlay,
    OverlayLocationTargetingPage,
    OverlaySetBudgetPage,
    OverlayConversionProfile,
    CampaignSummaryOverlay
  ];

  // Initialize step when overlay opens
  useEffect(() => {
    if (isOpen) {
      if (!searchParams.has('onboardingStep')) {
        console.log("Initializing new onboarding step");
        const newParams = new URLSearchParams(searchParams);
        newParams.set('onboardingStep', '0');
        newParams.set('overlayOpen', '1');
        setSearchParams(newParams);
      }
      setMounted(true);
      setAnimatingOut(false);
    } else {
      setAnimatingOut(true);
      const timer = setTimeout(() => setMounted(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  // Handle domain initialization
  useEffect(() => {
    if (!isOpen) return;

    if (!localDomain) {
      const storedDomain = localStorage.getItem(DOMAIN_KEY);
      if (!storedDomain) {
        handleClose();
      } else {
        setLocalDomain(storedDomain);
      }
    }
  }, [isOpen, localDomain]);

  const handleClose = () => {
    setAnimatingOut(true);
    setTimeout(() => {
      localStorage.removeItem('daily_budget');
      localStorage.removeItem('onboarding_overlay_location_data');
      // Clear onboarding params when closing
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('onboardingStep');
      newParams.delete('overlayOpen');
      setSearchParams(newParams);
      onClose();
      navigate('/manage-campaign');
    }, 300);
  };

  const getCurrentStep = useCallback((): number => {
    const step = searchParams.get('onboardingStep');
    return step ? Math.min(parseInt(step, 10), steps.length - 1) : 0;
  }, [searchParams, steps.length]);

  const goToStep = useCallback((stepNumber: number) => {
    if (isStepTransitioning) return;
    console.log(`Going to step: ${stepNumber}`);
    setIsStepTransitioning(true);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('onboardingStep', stepNumber.toString());
    newParams.set('overlayOpen', '1');
    setSearchParams(newParams);
    // Reset transition lock after a short delay
    setTimeout(() => setIsStepTransitioning(false), 100);
  }, [searchParams, setSearchParams, isStepTransitioning]);

  const handleStartComplete = useCallback((url: string) => {
    setLocalDomain(url);
    goToStep(1);
  }, [goToStep]);

  const handleStepComplete = useCallback((status: StepStatus) => {
    console.log(`Complete: ${status}`);
    if (status.isComplete && !isStepTransitioning) {
      const currentStep = getCurrentStep();
      const nextStep = currentStep + 1;
      if (nextStep >= steps.length) {
        handleClose();
      } else {
        goToStep(nextStep);
      }
    }
  }, [getCurrentStep, goToStep, steps.length, isStepTransitioning]);

  const handlePrevStep = useCallback(() => {
    if (isStepTransitioning) return;
    const prevStep = Math.max(getCurrentStep() - 1, 0);
    setIsStepTransitioning(true);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('onboardingStep', prevStep.toString());
    newParams.set('overlayOpen', '1');
    setSearchParams(newParams);
    setTimeout(() => setIsStepTransitioning(false), 100);
  }, [getCurrentStep, setSearchParams, searchParams, isStepTransitioning]);

  if (!mounted) return null;

  const currentStep = getCurrentStep();
  const CurrentPage = steps[currentStep];
  const isLastStep = currentStep === steps.length - 1;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto transition-opacity duration-300">
      <div 
        className={`fixed inset-0 bg-black/50 transition-opacity duration-300 ${
          animatingOut ? 'opacity-0' : 'opacity-100'
        }`} 
        onClick={handleClose}
      />
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div 
          className={`relative bg-white rounded-lg shadow-xl w-full p-6 
            transition-all duration-300 transform ${
            currentStep === 0 ? 'max-w-2xl' : 'max-w-4xl'} ${
            animatingOut
              ? 'opacity-0 scale-95 translate-y-4'
              : 'opacity-100 scale-100 translate-y-0'
          }`}
        >
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            ×
          </button>
          
          <div className="transition-opacity duration-300">
            {currentStep === 0 ? (
              <CurrentPage onComplete={handleStartComplete} />
            ) : (
              <CurrentPage 
                url={localDomain} 
                onStepComplete={handleStepComplete} 
              />
            )}
          </div>
          
          {currentStep > 0 && (
            <div className="flex justify-between mt-8">
              <button
                onClick={handlePrevStep}
                className="px-4 py-2 rounded bg-gray-300 text-gray-500 hover:bg-gray-400"
              >
                Previous
              </button>
              
              <button
                onClick={() => handleStepComplete({ isComplete: true })}
                className="px-4 py-2 rounded bg-primary hover:bg-primaryDark text-white"
              >
                {isLastStep ? 'Finish' : 'Next'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingOverlay;