import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '@/services/api-config';
import { useAuth } from '@/utils/AuthProvider';
import { BudgetData } from '@/pages/page_types/budget';
import { HomepageData } from '@/pages/page_types/homepage';
import { ICampaignContext } from './CampaignContextInterface';
import { ACTIVE_CAMPAIGN_ID } from '@/types/constants';
import { AdPreviewResponse } from '@/types/api/AdPreviewTypes';
import { LocationData, LocationSelection } from '@/pages/page_types/location_data';
import { FetchAdCreativeResponse, AdItem, CreativeType } from '@/pages/page_types/ad_content';
import { Campaign } from '@/pages/page_types/campaigns';
import { ApiLoadCampaignResponse } from '@/types/api/CampaignTypes';

const CampaignContext = createContext<ICampaignContext | null>(null);

export const CampaignProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { session, refreshSession } = useAuth();
  
  // State for API data
  const [homepageData, setHomepageData] = useState<HomepageData | null>(null);
  const [positiveKeywords, setPositiveKeywords] = useState<string[]>([]);
  const [negativeKeywords, setNegativeKeywords] = useState<string[]>([]);
  const [budgetData, setBudgetData] = useState<BudgetData | null>(null);
  const [suggestedBudget, setSuggestedBudget] = useState<number>(0);
  const [budgetHistory, setBudgetHistory] = useState<any[]>([]);
  const [headlines, setHeadlines] = useState<string[]>([]);
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [domain, setDomain] = useState<string>('');
  const [locationData, setLocationData] = useState<LocationData[]>([]);
  const [adCreatives, setAdCreatives] = useState<AdItem[]>([]);
  const [activeCampaign, setActiveCampaign] = useState<Campaign>();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  
  // Loading states
  const [homepageDataLoading, setHomepageDataLoading] = useState(false);
  const [keywordsLoading, setKeywordsLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [campaignStatusLoading, setCampaignStatusLoading] = useState(false);
  const [locationDataLoading, setLocationDataLoading] = useState(false);
  const [adCreativeLoading, setAdCreativeLoading] = useState(false);

  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  
  // Track if data has been fetched
  const [hasHomepageData, setHasHomepageData] = useState(false);
  const [hasKeywords, setHasKeywords] = useState(false);
  const [hasBudget, setHasBudget] = useState(false);
  const [hasPreview, setHasPreview] = useState(false);
  const [hasLocationData, setHasLocationData] = useState(false);
  const [hasAdCreative, setHasAdCreative] = useState(false);
  const [hasCampaigns, setHasCampaigns] = useState(false);

  const [savingAdCreative, setSavingAdCreative] = useState(false);

  const [campaignStatus, setCampaignStatus] = useState<'ACTIVE' | 'PAUSED' | 'UNKNOWN'>('UNKNOWN');

  const [showOnboarding, setShowOnboarding] = useState(false);


  const [campaignId, setCampaignId] = useState<string>(() => {
    return localStorage.getItem(ACTIVE_CAMPAIGN_ID)!;
  });


  const getHeaders = async () => {
    if (!session?.access_token) {
      await refreshSession();
    }
    if (!session?.access_token) {
      throw new Error('No access token available');
    }
    return {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };
  };

  const toggleCampaignStatus = async () => {
    if (!campaignId || !session?.access_token) {
      toast.error('Unable to update campaign status. Please try again.');
      return;
    }

    const newStatus = campaignStatus === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

    try {
        const headers = await getHeaders()
        const response = await axios.post(
            `${baseUrl}/googleads/campaign/status`,
            { 
            campaign_id: campaignId,
            status: newStatus 
            },
            { headers }
        );

        if (response.status === 200) {
            setCampaignStatus(newStatus);
            toast.success(`Campaign ${newStatus.toLowerCase()} successfully`);
        } else {
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        console.error('Error updating campaign status:', error);
        if (axios.isAxiosError(error)) {
            toast.error(`Failed to update campaign status: ${error.response?.data?.message || error.message}`);
        } else {
            toast.error('An unexpected error occurred while updating campaign status');
        }
    }
  };

  const setActiveCampaignFull = useCallback(async (campaign: Campaign) => {
    if (!campaign.isOnboarded) {
      setShowOnboarding(true);
    }
    setActiveCampaign(campaign);
    setCampaignId(campaign.id);
    setDomain(campaign.domain);
    localStorage.setItem(ACTIVE_CAMPAIGN_ID, campaign.id);
    resetAllData();
  }, [])

  const handleSaveAdCreative = useCallback(async () => {
    const toastId = toast.loading("Saving changes...");
    setSavingAdCreative(true);
    
    try {
      if (!session?.access_token) {
        await refreshSession();
      }

      const operations = adCreatives
        .filter(item => item.status !== 'SYNCED')
        .map(item => ({
          operation: item.status === 'PENDING_ADD' ? 'add' : 'remove',
          text: item.text,
          field_type: item.type
        }));

      if (operations.length > 0) {
        const headers = {
          Authorization: `Bearer ${session?.access_token}`,
          'Content-Type': 'application/json',
        };

        const response = await axios.post(
          `${baseUrl}/googleads/campaign/creative/update`,
          {
            campaign_id: campaignId,
            ad_creative_operations: operations
          },
          { headers }
        );

        if (!response.data) {
          throw new Error('Failed to save changes');
        }

        setAdCreatives(prev => 
          prev
            .filter(item => item.status !== 'PENDING_REMOVE')
            .map(item => ({ ...item, status: 'SYNCED' as const }))
        );

        setHasAdCreative(true);

        toast.update(toastId, {
          render: "Changes saved successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.dismiss(toastId);
      }
    } catch (error) {
      console.error('Save error:', error);
      toast.update(toastId, {
        render: "Failed to save changes. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setSavingAdCreative(false);
    }
  }, []);

  const fetchAdCreativeIfNeeded = useCallback(async () => {

    if (adCreativeLoading || hasAdCreative) {
      return;
    }

    setAdCreativeLoading(true);

    try {
      const headers = await getHeaders();
      const response = await axios.get<FetchAdCreativeResponse>(
        `${baseUrl}/googleads/campaign/${campaignId}/creative`,
        { headers }
      );
  
      const { headlines, descriptions } = response.data;
  
      const loadedCreativeItems: AdItem[] = [];
  
      for (const headline of headlines) {
        loadedCreativeItems.push({
          text: headline,
          status: 'SYNCED' as const,
          type: 'HEADLINE' as CreativeType
        });
      }
  
      for (const description of descriptions) {
        loadedCreativeItems.push({
          text: description,
          status: 'SYNCED' as const,
          type: 'DESCRIPTION' as CreativeType
        });
      }
  
      setAdCreatives(loadedCreativeItems);
      setHasAdCreative(true);
    } catch (err) {
      toast.error('Failed to load ad content');
      console.error('Error loading ad content:', err);
    } finally {
      setAdCreativeLoading(false);
    }

  }, [hasAdCreative, adCreativeLoading]);

  const fetchPreviewIfNeeded = useCallback(async () => {
    if (hasPreview || previewLoading) return;

    setPreviewLoading(true);

    try {
        const headers = await getHeaders();
        
        const responseData = await axios.get<AdPreviewResponse>(
            `${baseUrl}/googleads/campaign/${campaignId}/preview`,
            { headers }
        );

        const newHeadlines: string[] = [];
        const newDescriptions: string[] = [];

        for (const item of responseData.data.headlines) {
            newHeadlines.push(item);
        }
        for (const item of responseData.data.descriptions) {
            newDescriptions.push(item);
        }
      
        // Fix 2: Set state with new arrays instead of using closure values
        setDomain(responseData.data.url);
        setHeadlines(newHeadlines);
        setDescriptions(newDescriptions);
        setHasPreview(true);
    } catch (error) {
        console.error('Error fetching preview data:', error);
        toast.error('Failed to fetch preview data');
    } finally {
        setPreviewLoading(false);
    }

  }, [hasPreview, previewLoading]);

  const fetchLocationDataIfNeeded = useCallback(async () => {
    if (hasLocationData || locationDataLoading) return;

    setLocationDataLoading(true);

    try {
      const headers = await getHeaders();

      const response = await axios.get(
        `${baseUrl}/googleads/campaign/${campaignId}/locations`,
        { headers }
      );

      if (response.status === 200) {
        setLocationData(response.data.locations);
        setHasLocationData(true);
      } else {
        throw new Error('Unexpected response status');
      }

    } catch (error) {
      console.error('Error fetching location data:', error);
      toast.error('Failed to fetch location data');
    } finally {
      setLocationDataLoading(false);
    }
  }, [hasLocationData, locationDataLoading, campaignId]);

  const handleFetchCampaignsIfNeeded = useCallback(async () => {
    console.log(`Has campaigns: [${hasCampaigns}]`);
    if (hasCampaigns || loadingCampaigns) return;

    setLoadingCampaigns(true);
    try {
      const headers = await getHeaders();

      const response = await axios.get<ApiLoadCampaignResponse>(
        `${baseUrl}/all_campaigns`,
        { headers }
      );

      const campaigns: Campaign[] = [];

      for (const item of response.data.campaigns) {
        const activeCampaignId = localStorage.getItem(ACTIVE_CAMPAIGN_ID)
        if (activeCampaignId === undefined) {
          if (item.is_starred) {
            localStorage.setItem(ACTIVE_CAMPAIGN_ID, item.campaign_id);
            setActiveCampaign({
              id: item.campaign_id,
              domain: item.domain,
              isOnboarded: item.is_onboarded,
            });
          }
        } else {
          if (activeCampaignId === item.campaign_id) {
            setActiveCampaign({
              id: item.campaign_id,
              domain: item.domain,
              isOnboarded: item.is_onboarded
            });
          }
        }

        campaigns.push({
          id: item.campaign_id,
          domain: item.domain,
          isOnboarded: item.is_onboarded
        });
      }

      setCampaigns(campaigns);
      setHasCampaigns(true);
    } catch (error) {
      toast.error("Error fetching campaigns");
      setHasCampaigns(false);
    } finally {
      setLoadingCampaigns(false);
    }
  }, [hasCampaigns]);

  const handleAddLocation = useCallback(async (newLocation: LocationSelection) => {
    if (!campaignId) {
      toast.error('Campaign ID is required');
      return false;
    }

    try {
      const headers = await getHeaders();
      
      // Check for duplicate location
      const isDuplicate = locationData.some(existingLocation => {
        if (newLocation.type === 'LOCATION' && existingLocation.type === 'LOCATION') {
          return newLocation.geo_target_constant === existingLocation.geo_target_constant;
        }

        if (newLocation.type === 'PROXIMITY' && existingLocation.type === 'PROXIMITY') {
          const sameRadius = newLocation.radius_miles === existingLocation.radius_miles;
          const sameCoordinates =
            newLocation.coordinates?.lat === existingLocation.coordinates.lat &&
            newLocation.coordinates?.lng === existingLocation.coordinates.lng;
          return sameRadius && sameCoordinates;
        }

        return false;
      });

      if (isDuplicate) {
        toast.warning('This location is already in your targeting list');
        return false;
      }

      // Check for mixing targeting types
      if (locationData.length > 0 && newLocation.type !== locationData[0].type) {
        toast.error(`Cannot mix ${newLocation.type} and ${locationData[0].type} targeting types`);
        return false;
      }

      const locationToAdd = {
        ...newLocation,
        geo_target_constant: newLocation.geo_target_constant || null,
        coordinates: newLocation.coordinates || { lat: 0, lng: 0 },
        radius_miles: newLocation.radius_miles || 0,
        average_cpm: 0,
        average_cpv: 0,
        average_cpe: 0,
        average_cpc: 0,
        average_cost: 0,
        clicks: 0,
        conversions: 0,
        cost_per_conversion: 0
      };

      const response = await axios.post(
        `${baseUrl}/googleads/campaign/locations`,
        {
          campaign_id: campaignId,
          locations: [locationToAdd]
        },
        { headers }
      );

      if (response.status === 200) {
        setLocationData([...locationData, locationToAdd]);
        toast.success('Location added successfully');
        return true;
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error adding location:', error);
      toast.error('Failed to add location');
      return false;
    }
  }, [campaignId, locationData]);



  const handleRemoveLocation = useCallback(async (resourceName: string) => {
    if (!campaignId) {
      toast.error('Campaign ID is required');
      return false;
    }

    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${baseUrl}/googleads/campaign/locations/remove`,
        {
          campaign_id: campaignId,
          resource_name: resourceName
        },
        { headers }
      );

      if (response.status === 200) {
        setLocationData(locationData.filter(loc => loc.resource_name !== resourceName));
        toast.success('Location removed successfully');
        return true;
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error removing location:', error);
      toast.error('Failed to remove location');
      return false;
    }
  }, [campaignId, locationData]);

  const fetchHomepageDataIfNeeded = useCallback(async () => {
    if (hasHomepageData || homepageData || homepageDataLoading) return;
    
    setHomepageDataLoading(true);
    try {
      const headers = await getHeaders();
      const response = await axios.get(`${baseUrl}/googleads/campaign/${campaignId}/homepage`, { headers });
      setHomepageData({
        impressions: response.data.impressions,
        clicks: response.data.clicks,
        ctr: response.data.ctr,
        cost_micros: response.data.cost_micros,
        all_conversions: response.data.all_conversions,
        status: response.data.status
      });
      setHasHomepageData(true);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      toast.error('Failed to fetch analytics data');
    } finally {
      setHomepageDataLoading(false);
    }
  }, [campaignId, hasHomepageData, homepageData, homepageDataLoading]);

  const fetchKeywordsIfNeeded = useCallback(async () => {
    if (hasKeywords || keywordsLoading) return;

    setKeywordsLoading(true);

    try {
        const headers = await getHeaders();
        const response = await axios.get(`${baseUrl}/googleads/campaign/${campaignId}/keywords`, { headers });
        setPositiveKeywords(response.data.positive_keywords || []);
        setNegativeKeywords(response.data.blocked_keywords || []);
        setHasKeywords(true);
    } catch (error) {
        console.error('Error fetching keywords:', error);
        toast.error('Failed to fetch keywords');
    } finally {
        setKeywordsLoading(false);
    }

  }, [hasKeywords, keywordsLoading]);

  const handleUpdateKeywords = async (positive: string[], negative: string[]) => {
    if (!campaignId) {
      toast.error('Unable to update keywords. Please try again.');
      return;
    } else if (!session?.access_token) {
      await refreshSession();
    }

    if (!session?.access_token) {
      toast.error("Network error please contact support");
      return;
    }

    const headers = {
      Authorization: `Bearer ${session!.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${baseUrl}/googleads/campaign/keywords`,
        {
          campaign_id: campaignId,
          positive_keywords: positive,
          blocked_keywords: negative
        },
        { headers }
      );

      if (response.status === 200) {
        setPositiveKeywords(positive);
        setNegativeKeywords(negative);
        toast.success('Keywords updated successfully');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating keywords:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', error.response?.data);
        toast.error(`Failed to update keywords: ${error.response?.data?.message || error.message}`);
      } else {
        toast.error('An unexpected error occurred while updating keywords');
      }
    }
  };

  const fetchCampaignStatusIfNeeded = useCallback(async () => {
    if (campaignStatus !== 'UNKNOWN' || campaignStatusLoading) {
      return;
    }

    setCampaignStatusLoading(true);

    try {
        const headers = await getHeaders();
        const response = await axios.get(
            `${baseUrl}/googleads/campaign/${campaignId}/status`,
            { headers }
        );

        console.log(`Fetched campaign status: [${response.data}]`)

        setCampaignStatus(response.data.status);
    } catch (error) {
        console.error('Error fetching campaign status:', error);
        toast.error('Failed to fetch campaign status');
    } finally {
      setCampaignStatusLoading(false);
    }
  }, [campaignId, campaignStatus]);

  const fetchBudgetIfNeeded = useCallback(async () => {
    if (hasBudget || budgetLoading) return;

    setBudgetLoading(true);

    try {
        const headers = await getHeaders();
        const response = await axios.get(`${baseUrl}/googleads/campaign/${campaignId}/budget`, { headers });

        if (response.status === 200) {
            setSuggestedBudget(response.data.suggested_budget / 1000000);
            setBudgetData(response.data.budget_data.current_budget);
            setBudgetHistory(response.data.budget_data.budget_history);
            setHasBudget(true);
        } else {
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        console.error('Error fetching keywords:', error);
        toast.error('Failed to fetch keywords');
    } finally {
        setBudgetLoading(false);
    }
  }, [hasBudget, budgetLoading])
  
  const resetAllData = useCallback(() => {
    setHasHomepageData(false);
    setHasKeywords(false);
    setHasBudget(false);
    setHasPreview(false);
    setHasLocationData(false);
    setHasAdCreative(false);
  }, []);

  const value = {
    homepageData,
    fetchHomepageDataIfNeeded,
    homepageDataLoading,
    positiveKeywords,
    negativeKeywords,
    fetchKeywordsIfNeeded,
    suggestedBudget,
    budgetData,
    budgetHistory,
    setBudgetData,
    keywordsLoading,
    headlines,
    descriptions,
    domain,
    campaignStatus,
    budgetLoading,
    previewLoading,
    setPreviewLoading,
    toggleCampaignStatus,
    resetAllData,
    setPositiveKeywords,
    setNegativeKeywords,
    handleUpdateKeywords,
    fetchBudgetIfNeeded,
    fetchPreviewIfNeeded,
    fetchCampaignStatusIfNeeded,
    fetchLocationDataIfNeeded,
    locationDataLoading,
    campaignStatusLoading,
    setLocationData,
    locationData,
    handleAddLocation,
    handleRemoveLocation,
    fetchAdCreativeIfNeeded,
    adCreativeLoading,
    setAdCreatives,
    adCreatives,
    handleSaveAdCreative,
    campaigns,
    handleFetchCampaignsIfNeeded,
    setActiveCampaign,
    activeCampaign,
    showOnboarding,
    setShowOnboarding,
    setActiveCampaignFull,
    hasCampaigns,
    loadingCampaigns
  };

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaign = () => {
  const context = useContext(CampaignContext);
  if (!context) {
    throw new Error('useCampaign must be used within a CampaignProvider');
  }
  return context;
};