import React, { useState, useEffect, useCallback } from 'react';
import { Slider } from '@/components/ui/slider';
import { Card, CardHeader, CardContent } from '@/components/ui/card';
import { baseUrl } from '@/services/api-config';
import { StepStatus } from '@/utils/Interfaces';
import { useAuth } from '@/utils/AuthProvider'
import axios from 'axios';

interface PageProps {
  url: string;
  onStepComplete?: (status: StepStatus) => void;
}

interface BillingState {
  user_id: string;
  daily_budget: number;
}

const OverlaySetBudgetPage: React.FC<PageProps> = ({ url, onStepComplete }) => {
  const MINIMUM_BUDGET = 10;
  const [dailyBudget, setDailyBudget] = useState(MINIMUM_BUDGET);
  const [budgetError, setBudgetError] = useState<string | null>(null);
  const { session, refreshSession } = useAuth();
  const [suggestedBudget, setSuggestedBudget] = useState<number | null>(null);
  const [currentSpend, setCurrentSpend] = useState<BillingState | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const calculateFees = (budget: number) => {
    const dailyFee = budget * 0.1;
    const weeklyFee = dailyFee * 7;
    return { dailyFee, weeklyFee };
  };

  const handleBudgetChange = useCallback((value: number[]) => {
    const newBudget = value[0];
    setDailyBudget(newBudget);
    localStorage.setItem('daily_budget', newBudget.toString());
    
    if (onStepComplete && newBudget >= MINIMUM_BUDGET) {
      onStepComplete({
        isComplete: false,
        message: undefined
      });
    }
  }, [onStepComplete, MINIMUM_BUDGET]);

  const fetchSuggestedBudget = async () => {
    try {
      const suggested_budget = localStorage.getItem(`${url}_SUGGESTED_BUDGET`);

      if (!suggested_budget) {
        const resp = await axios.get(`${baseUrl}/googleads/campaign/budget/suggestion?url=${encodeURIComponent(url)}`);

        if (resp.data.suggested_budget) {
          const budget = Math.max(MINIMUM_BUDGET, Number(resp.data.suggested_budget)/1000000);
          setSuggestedBudget(budget);
          localStorage.setItem(`${url}_SUGGESTED_BUDGET`, budget.toString());
        }
      } else {
        setSuggestedBudget(Math.max(MINIMUM_BUDGET, Number(suggested_budget)));
      }
    } catch (error) {
      console.error('Failed to fetch suggested budget:', error);
      setBudgetError('Unable to load the suggested budget. You can still adjust your current budget.');
    }
  };

  useEffect(() => {
    const fetchCurrentSpend = async () => {
      if (!session) {
        await refreshSession();
      }

      if (!session) {
        console.error("Unable to refresh session");
        return;
      }

      const headers = {
        Authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(
          `${baseUrl}/api/dailyspend`,
          { headers }
        );
        setCurrentSpend(response.data);
      } catch (error) {
        console.error('Failed to fetch current spend:', error);
        setBudgetError('Unable to load current spend information. Please try again later.');
      }
    };

    const initialize = async () => {
      try {
        await Promise.all([
          fetchCurrentSpend(),
          fetchSuggestedBudget()
        ]);
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, [url]);

  useEffect(() => {
    if (!onStepComplete) return;

    const isBudgetValid = dailyBudget >= MINIMUM_BUDGET;

    onStepComplete({
      isComplete: false,
      message: !isBudgetValid 
        ? 'Please set a valid daily budget'
        : undefined
    });
  }, [dailyBudget, onStepComplete]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-6">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  // Calculate new budget fees
  const { dailyFee, weeklyFee } = calculateFees(dailyBudget);
  
  // Calculate the total daily and weekly spend after adding new campaign
  const totalDailySpend = (currentSpend?.daily_budget || 0) + dailyBudget;
  const { weeklyFee: totalWeeklyFee } = calculateFees(totalDailySpend);

  const maxBudget = Math.max(100, suggestedBudget || 100);

  return (
    <div className="p-6 max-w-3xl mx-auto">
      <Card>
        <CardHeader>
          <h2 className="text-2xl font-semibold">Set Campaign Budget</h2>
          {currentSpend && currentSpend.daily_budget > 0 && (
            <p className="text-gray-600">
              Current platform spend: ${currentSpend.daily_budget.toFixed(2)}/day
            </p>
          )}
        </CardHeader>
        <CardContent>
          {suggestedBudget && suggestedBudget > dailyBudget && (
            <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-md">
              <p className="text-blue-700">
                Based on our AI analysis, a budget of ${suggestedBudget.toFixed(2)}/day 
                could improve your campaign performance.
              </p>
            </div>
          )}

          {budgetError && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-700">{budgetError}</p>
            </div>
          )}

          <div className="space-y-6">
            <Slider
              min={MINIMUM_BUDGET}
              max={maxBudget}
              step={1}
              value={[dailyBudget]}
              onValueChange={handleBudgetChange}
              className="mt-6"
            />

            <div className="flex justify-between text-sm text-gray-600">
              <span>${MINIMUM_BUDGET}</span>
              <span>${maxBudget}</span>
            </div>

            <div className="text-2xl font-bold">
              ${dailyBudget.toFixed(2)}/day
              <span className="text-sm text-gray-600 ml-2">USD</span>
            </div>

            <div className="p-4 bg-gray-50 rounded-md space-y-3">
              <h3 className="font-semibold">Budget Summary</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">This campaign's daily budget</span>
                  <span className="font-medium">${dailyBudget.toFixed(2)}/day</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">This campaign's weekly fee</span>
                  <span className="font-medium">${weeklyFee.toFixed(2)}/week</span>
                </div>
                {currentSpend && currentSpend.daily_budget > 0 && (
                  <>
                    <div className="pt-2 border-t">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Total platform daily spend</span>
                        <span className="font-medium">${totalDailySpend.toFixed(2)}/day</span>
                      </div>
                      <div className="flex justify-between mt-1">
                        <span className="text-gray-600">Total platform weekly fee</span>
                        <span className="font-medium">${totalWeeklyFee.toFixed(2)}/week</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default OverlaySetBudgetPage;